<template>
  <div class="page">
    <!--标题-->
    <ls-title title="机构信息"></ls-title>

    <template v-if="!editShow">
      <div style="background-color: white;">
        <div class="box">
          <div>
            <span style="color: #285df0;font-size: 19px;font-weight: 600;">基本信息</span>
          </div>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <span class="title">机构名称</span>
                <span class="content">{{unitData.name}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <span class="title">系统名称</span>
                <span class="content">{{unitData.sysDisplayData.sysName}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <span class="title">联&ensp;系&ensp;人 </span>
                <span class="content">{{unitData.linkMan}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <span class="title">联系电话</span>
                <span class="content">{{unitData.linkPhone}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <span class="title">机构地址</span>
                <span class="content">{{unitData.addressShow}}</span>
              </div>
            </el-col>
            <el-col :span="12">
               <div style="vertical-align: middle;">
                <span class="title">LOGO</span>
                <el-image style="width: 250px; height: 120px;vertical-align: top;" :src="unitData.sysLogoUrl"></el-image>
              </div>
            </el-col>
          </el-row>
          <hr />
          <div>
            <span style="color: #285df0;font-size: 19px;font-weight: 600;">系统资料</span>
          </div>
          <el-row class="row">
            <el-col :span="24">
              <div>
                <span class="title">账&emsp;&emsp;号</span>
                <span class="content">{{unitData.login}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
              <div>
                <span class="title">密&emsp;&emsp;码</span>
                <el-button type="warning" style="margin-left:5px;" size="small" @click="psdVisible=true">修改密码</el-button>
              </div>
            </el-col>
          </el-row>
          <hr />
          <div>
            <span style="color: #285df0;font-size: 19px;font-weight: 600;">授权数量</span>
          </div>
          <el-row class="row">
            <el-col :span="8">
              <div>
                <span class="title">电话授权数量</span>
                <span class="content">{{unitData.resDistributeData.voiceNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">电话使用数量</span>
                <span class="content">{{unitData.resReduceData.voiceNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">电话剩余数量</span>
                <span class="content">{{unitData.resDistributeData.voiceNum - unitData.resReduceData.voiceNum}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="8">
              <div>
                <span class="title">短信授权数量</span>
                <span class="content">{{unitData.resDistributeData.smsNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">短信使用数量</span>
                <span class="content">{{unitData.resReduceData.smsNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">短信剩余数量</span>
                <span class="content">{{unitData.resDistributeData.smsNum - unitData.resReduceData.smsNum}}</span>
              </div>
            </el-col>
          </el-row>
          
          <hr />
          <el-row class="row">
            <el-col :span="24">
              <div style="display:flex;align-items: center;">
                <span class="title">健康预警来源</span>
                <div style="margin-top:3px;">
                  <el-checkbox label="医疗设备" v-model="medicalType.medicalSwitch" size="large"   disabled></el-checkbox>
                  <el-checkbox label="非医疗设备" v-model="medicalType.noMedicalSwitch" size="large"  disabled></el-checkbox>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
              <div class="grid-content bg-purple" style="display:flex;align-items: center;">
                <el-button type="primary" style="float:right;" size="small" @click="edit">编辑</el-button>
              </div>
            </el-col>
          </el-row>
        </div>

            <!--新增弹框-->
          <el-dialog title="修改密码" v-model="psdVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="30%" >
            <div>
              <span>新密码：</span>
              <el-input v-model="password" placeholder="输入新密码" style="width: 80%" ></el-input>
            </div>
             <template #footer>
              <span class="dialog-footer">
                <el-button size="small" @click="psdVisible=false">取消</el-button>
                <el-button type="primary" @click="changePwd" size="small">确定</el-button>
              </span>
            </template>
          </el-dialog>

      </div>
    </template>

    <!-- 编辑 -->
    <template v-if="editShow">
      <EditBUnitInfo ref="childView" @cancel="editShow=false" @update="update"></EditBUnitInfo>
    </template>
  </div>
</template>

<script>
import EditBUnitInfo from './EditBUnitInfo.vue'
import { getCustomerInfo,updateCustomerInfo } from '../../../api/api'
import session from '../../../store/store'
import until from '../../../until/until'

export default {
  components: {
    EditBUnitInfo
  },
  data() {
    return {
      psdVisible:false,
      medicalType:{
        medicalSwitch:true,
        noMedicalSwitch:false,
      },
      editShow: false,
      password:'',
      unitData:{
        sysSetData:{},
        sysDisplayData:{},
        resDistributeData:{},
        resReduceData:{},
        infoData:{},
        entryDisplayData:{}
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getCustomerInfo();
    },
    update() {
      this.editShow=false;
      this.getCustomerInfo();
    },
    getCustomerInfo() {
      var param = {
        uuid: session.getLoginInfo().unitUuid,
      }
      getCustomerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.unitData = res.data.dataInfo;
          this.unitData.addressShow = this.unitData.address.replaceAll("#","");

          if (until.isNULL(this.unitData.sysLogoUrl)) {
            this.unitData.sysLogoUrl = require('../../../assets/img/ls.png');
          }
          if(this.unitData.medicalSwitch == 1) {
            this.medicalType.medicalSwitch = true;
          }else {
            this.medicalType.medicalSwitch = false;
          }

          if(this.unitData.noMedicalSwitch == 1) {
            this.medicalType.noMedicalSwitch = true;
          }else {
            this.medicalType.noMedicalSwitch = false;
          }

          session.setSysName(this.unitData.sysDisplayData.sysName);
          session.setSysLogoUrl(this.unitData.sysLogoUrl);
        } 
      })
    },
    edit() {
      this.editShow = true;
      setTimeout(() => {
        this.$refs.childView.getParentData(this.unitData);
      }, 200);
    },
    changePwd() {
      if (until.isNULL(this.password)) {
        this.$message({ message: '请输入密码',  type: 'warning'});
          return;
      }
      var param = {
        uuid: session.getLoginInfo().unitUuid,
        parentUnitUuid:session.getLoginInfo().parentUnitUuid,
        password:this.$md5(this.password),
      }
      updateCustomerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.psdVisible = false;
          this.$message({ message: '修改成功',  type: 'success'});
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
      
    },
  }
}
</script>

<style lang="scss" scoped>
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 20px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  padding: 30px;
  width: 75%;
}
.content {
  font-size: 16px;
}
.row {
  margin: 20px 0px 40px 0px;
}
</style>