<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="160px" class="demo-ruleForm" style="margin-bottom: 0px;">
      <div style="background-color: white;">
        <div class="box">
          <div>
            <span style="color: #285df0;font-size: 19px;font-weight: 600;">基本信息</span>
          </div>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <el-form-item prop="name">
                  <template #label><span class="title">机构名称</span></template>
                  <!-- <span class="content">深圳市莱尚科技有限公司</span> -->
                  <el-input v-model="ruleForm.name" placeholder="请输入机构名称" maxlength="15" show-word-limit class="input"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">系统名称</span></template>
                  <el-input v-if="unitData.sysSetData.showChangeSwitch==1" v-model="ruleForm.sysDisplayData.sysName" placeholder="请输入系统名称" maxlength="50" show-word-limit class="input" ></el-input>
                  <span v-if="unitData.sysSetData.showChangeSwitch==0" class="content">{{ruleForm.sysDisplayData.sysName}}</span>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">联&ensp;系&ensp;人 </span></template>
                  <el-input v-model="ruleForm.linkMan" placeholder="请输入联系人" maxlength="10" show-word-limit class="input"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">联系电话</span></template>
                  <el-input v-model="ruleForm.linkPhone" placeholder="请输入联系电话" class="input"></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">机构地址</span></template>
                  <ls-address class="input" @adr-change="getAddress" :province="this.address.province" :city="this.address.city" :district="this.address.district" :street="this.address.street"></ls-address>
                  <el-input type="text" v-model="this.address.detail" placeholder="请输入详细地址" class="input" style="margin-top:20px;" maxlength="50" show-word-limit></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <el-form-item>
                  <template #label><span class="title">LOGO</span></template>
                  <el-image v-if="unitData.sysSetData.showChangeSwitch==0" style="width: 250px; height: 120px;vertical-align: top;" :src="unitData.sysLogoUrl"></el-image>
                  <div v-if="unitData.sysSetData.showChangeSwitch==1">
                    <ls-file-upload :limitCount="1" :type="2" :autoUpload="false" :files="sysFileList" @onChange="fileOnChange" @deleteFile="sysDeleteFile"></ls-file-upload>
                    <div> <span>(250*120)</span> </div>
                  </div>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <hr />
          <div>
            <span style="color: #285df0;font-size: 19px;font-weight: 600;">系统资料</span>
          </div>
          <el-row class="row">
            <el-col :span="12">
              <el-form-item prop="login">
                  <template #label><span class="title">账&emsp;&emsp;号</span></template>
                  <el-input v-model="ruleForm.login" placeholder="请输入账号" maxlength="15" show-word-limit class="input" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row class="row">
            <el-col :span="12">
              <div>
                <el-form-item prop="name">
                  <template #label><span class="title">密&emsp;&emsp;码</span></template>
                  <el-input v-model="ruleForm.name" placeholder="请输入密码" maxlength="50" show-word-limit class="input"></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row> -->
          <hr />
          <div>
            <span style="color: #285df0;font-size: 19px;font-weight: 600;">授权数量</span>
          </div>
          <el-row class="row">
            <el-col :span="8">
              <div>
                <span class="title">电话授权数量</span>
                <span class="content">{{unitData.resDistributeData.voiceNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">电话使用数量</span>
                <span class="content">{{unitData.resReduceData.voiceNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">电话剩余数量</span>
                <span class="content">{{unitData.resDistributeData.voiceNum - unitData.resReduceData.voiceNum}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="8">
              <div>
                <span class="title">短信授权数量</span>
                <span class="content">{{unitData.resDistributeData.smsNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">短信使用数量</span>
                <span class="content">{{unitData.resReduceData.smsNum}}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span class="title">短信剩余数量</span>
                <span class="content">{{unitData.resDistributeData.smsNum - unitData.resReduceData.smsNum}}</span>
              </div>
            </el-col>
          </el-row>
          <hr />
          <el-row class="row">
            <el-col :span="24">
              <div style="display:flex;align-items: center;">
                  <span class="title">健康预警来源</span>
                  <div style="margin-top:3px;">
                    <el-checkbox label="医疗设备" v-model="medicalType.medicalSwitch" size="large"  :checked="medicalType.medicalSwitch"></el-checkbox>
                    <el-checkbox label="非医疗设备" v-model="medicalType.noMedicalSwitch" size="large" :checked="medicalType.noMedicalSwitch"></el-checkbox>
                  </div>
                </div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
              <div class="grid-content bg-purple" style="display:flex;align-items: center;">
                <el-button type="primary" style="float:right;" size="small" @click="cancel">返回</el-button>
                <el-button type="primary" style="float:right;" size="small" @click="sure('ruleForm')">提交</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
    <ls-cropper v-if="cropperVisible" :base64="this.base64" :type="this.fileType" :width="250" :height="120"  @data="getCopperData" @cancel="closeCropperDialog" style="width:100%;"></ls-cropper>
  </div>
</template>

<script>
import {updateCustomerInfo,deleteLogoFile,uploadLogoFileEx } from '../../../api/api'
import session from '../../../store/store'
import crypto from '../../../until/crypto'
import until from '../../../until/until'

export default {
  data() {
    return {
      cropperVisible:false,
      sysFileList: [],
      sysFileUrl:{},
      // sysFileData: {
      //   customUuid: '',
      //   unitUuid:'',
      //   logoType:2
      // }, //图片上传接口请求参数
      base64:'',
      fileType:0,
      sysLogo:{},
      unitData:{
        sysSetData:{},
        sysDisplayData:{},
        resDistributeData:{},
        resReduceData:{},
        infoData:{},
        entryDisplayData:{}
      },
      ruleForm: {
        sysDisplayData:{},
        entryDisplayData:{}
      },
      address: {
          province:'',
          city:'',
          district:'',
          street:'',
          detail:'',
      },
      medicalType:{
        medicalSwitch:true,
        noMedicalSwitch:false,
      },
      rules: {
        name: [
          {required: true, message: '请输入机构名称', trigger: 'blur',}
        ],
        // sysName: [
        //   {required: true, message: '请输入系统名称', trigger: 'blur',}
        // ],
        login: [
          {required: true, message: '请输入账号', trigger: 'blur',}
        ],
      }
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    init() {

    },

    getParentData(data) {
      this.unitData = data;
      
      let addressArr = this.unitData.address.split("#");
      
      if(addressArr.length == 5) {
        this.address.province = addressArr[0];
        this.address.city = addressArr[1];
        this.address.district = addressArr[2];
        this.address.street = addressArr[3];
        this.address.detail = addressArr[4];
      }
  
      if(this.unitData.medicalSwitch == 1) {
        this.medicalType.medicalSwitch = true;
      }else {
        this.medicalType.medicalSwitch = false;
      }

      if(this.unitData.noMedicalSwitch == 1) {
        this.medicalType.noMedicalSwitch = true;
      }else {
        this.medicalType.noMedicalSwitch = false;
      }
      this.ruleForm = data;

      this.sysFileUrl.name = data.sysDisplayData.sysName
      this.sysFileUrl.url = data.sysLogoUrl

      this.sysFileList.push(this.sysFileUrl)
    },
    getAddress(data){
      this.address.province = data.selectProvince;
      this.address.city = data.selectCity;
      this.address.district =  data.selectDistrict;
      this.address.street = data.selectStreet
    },
    cancel() {
      this.$emit('cancel')
    },


    sure(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.update();
        } else {
          return false;
        }
      });
    },
    update() {
      let address = this.address.province + '#' + this.address.city + '#' + this.address.district + '#' + this.address.street + '#' + this.address.detail;

      if(address == '####') {
        this.ruleForm.address = '';
      }else {
        this.ruleForm.address = address;
      }

      if(this.medicalType.medicalSwitch) {
        this.ruleForm.medicalSwitch = 1;
      }else {
        this.ruleForm.medicalSwitch = 0;
      }

      if(this.medicalType.noMedicalSwitch) {
        this.ruleForm.noMedicalSwitch = 1;
      }else {
        this.ruleForm.noMedicalSwitch = 0;
      }

      var param = {
        uuid: session.getLoginInfo().unitUuid,
        parentUnitUuid:session.getLoginInfo().parentUnitUuid,
        name:this.ruleForm.name,
        login:this.ruleForm.login,
        address:this.ruleForm.address,
        linkMan:this.ruleForm.linkMan,
        linkPhone:this.ruleForm.linkPhone,
        medicalSwitch:this.ruleForm.medicalSwitch,
        noMedicalSwitch:this.ruleForm.noMedicalSwitch,
        sysDisplay:crypto.Encrypt(JSON.stringify(this.ruleForm.sysDisplayData)),
      };
      updateCustomerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '修改成功',  type: 'success'});
          this.close();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    close() {
      this.$emit('update')
    },
    // getUploadUrl() {
    //   return uploadLogoFile();
    // },
        // 图片上传成功
    // sysUploadSuccess(e) {
    //   console.info(e)
    //   if(e.code == 200) {
    //     this.$message.success("上传成功");
    //     this.sysLogo = e.dataInfo;
    //     this.ruleForm.sysDisplayData.sysLogo = this.sysLogo.logoFileName;
    //   }else {
    //     this.$message.error(e.message);
    //   }
    // },
    //图片删除
    sysDeleteFile(e, callback) {
      if (until.isNULL(this.sysLogo.logoFileName)) {
          return;
      }
      let param = {
        customUuid: session.getCustomerUuid(),
        unitUuid:session.getLoginInfo().unitUuid,
        logoType:2,
        logoFileName:this.sysLogo.logoFileName,
      };

      deleteLogoFile(param).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("删除成功");
          this.sysLogo = {};
          this.ruleForm.sysDisplayData.sysLogo = "";
          callback(res);
        }else {
          this.$message.error(res.data.message);
        }
      });
    },

    fileOnChange(file,type) {
      this.fileType = type
      const isIMAGE = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png'
      const isLt5M = file.raw.size / 1024 / 1024 < 5
      if (!isIMAGE) {
        this.$message({ showClose: true, message: '请选择 jpg、png 格式的图片',  type: 'warning'})
        return false
      }
      if (!isLt5M) {
        this.$message({ showClose: true,  message: '图片大小不能超过 5MB',  type: 'warning'})
        return false
      }
      let reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.cropperVisible = true;
        this.base64 = e.target.result;
      }
    },
    getCopperData(data) {
      let formData = new FormData();
      formData.append('file',data.blob)
      formData.append('customUuid',session.getCustomerUuid())
      formData.append('unitUuid',session.getLoginInfo().unitUuid)
      if(data.type == 2) {
        formData.append('logoType',2)
        this.sysFileUrl.url = data.dataURL;
      }
      
      uploadLogoFileEx(formData).then((res) => {
        if(res.data.code == 200) {
          this.$message.success("上传成功");
          if(data.type == 2) {
            this.sysLogo = res.data.dataInfo;
            this.ruleForm.sysDisplayData.sysLogo = this.sysLogo.logoFileName;
          }
        }else {
          this.$message.error(res.data.message);
        }
      });
    },
    closeCropperDialog() {
      this.cropperVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 100%;
}
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 25px;
  font-size: 16px;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}
</style>